// app-config.ts
import { RuntimeConfig } from "@src/runtime-config";

function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

export const appVersion: string = "2.0.0-feature-SEC-445-Disable-Mend-SAST-PR-pipeline.0";
export const appTimestamp: string = "2024-11-28T12:08:22.000Z";
export const commitId: string = "0487fa43b99e956df02b16a4622483deddeccc97";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/commit/0487fa43b99e956df02b16a4622483deddeccc97?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "29622";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/pullrequest/29622";
export const jiraTicketId: string = "SEC-445";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/SEC-445";

const localizeKey = "iKNI0OJMw3RLe";
const sentryDSNKey =
  "https://20e8bee1014c8ffa9f8b67f3d69a9709@o381590.ingest.sentry.io/4505912288411648";

const isNumberedEnv = isHostNumberedEnv(window.location.host);
const numberedEnv = isNumberedEnv ? getNumberedEnv(window.location.host) : null;

const developmentEu = {
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.eu",
    apiBaseComUrl: "https://core.api.dev.holobuilder.com",
    apiBaseEuUrl: "https://core.api.dev.holobuilder.eu",
    sphereDashboardUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.eu"
        : "https://sphere.dev.holobuilder.eu",
    dashboard20ComUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.com"
        : "https://sphere.dev.holobuilder.com",
    dashboard20EuUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.eu"
        : "https://sphere.dev.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.dev.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.dev.holobuilder.eu",
    sphere10Url: "https://www.dev.farosphere.com",
    sphereEntryPageApi: "https://entry.dev.holobuilder.eu/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.dev.farosphere.com",
    sphere10Auth0ClientId: "KBygi7sREPoE6KHQY58bPJKsahVcVZB6",
    streamAppAuth0ClientId: "q84k4DiTZc8qVYLfHYTAiE9e3gy7dqx0",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,directSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,directSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,directSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "faro-sphere"],
  },
  appEnv: "eu-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const developmentCom = {
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.com",
    apiBaseComUrl: "https://core.api.dev.holobuilder.com",
    apiBaseEuUrl: "https://core.api.dev.holobuilder.eu",
    sphereDashboardUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.com"
        : "https://sphere.dev.holobuilder.com",
    dashboard20ComUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.com"
        : "https://sphere.dev.holobuilder.com",
    dashboard20EuUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.eu"
        : "https://sphere.dev.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.dev.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.dev.holobuilder.eu",
    sphere10Url: "https://www.dev.farosphere.com",
    sphereEntryPageApi: "https://entry.dev.holobuilder.com/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.dev.farosphere.com",
    sphere10Auth0ClientId: "KBygi7sREPoE6KHQY58bPJKsahVcVZB6",
    streamAppAuth0ClientId: "q84k4DiTZc8qVYLfHYTAiE9e3gy7dqx0",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,directSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,directSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,directSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "bluebeam", "google", "faro-sphere"],
  },
  appEnv: "com-dev",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingEu = {
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.eu",
    apiBaseComUrl: "https://core.api.staging.holobuilder.com",
    apiBaseEuUrl: "https://core.api.staging.holobuilder.eu",
    sphereDashboardUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.eu"
        : "https://sphere.staging.holobuilder.eu",
    dashboard20ComUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.com"
        : "https://sphere.staging.holobuilder.com",
    dashboard20EuUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.eu"
        : "https://sphere.staging.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.staging.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.staging.holobuilder.eu",
    sphere10Url: "https://www.stg.farosphere.com",
    sphereEntryPageApi: "https://entry.staging.holobuilder.eu/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.stg.farosphere.com",
    sphere10Auth0ClientId: "KkDGLxrxvLL9AnHFpGytGGnt6v65EjvF",
    streamAppAuth0ClientId: "aVVKUke0lRt0QfRuf5VMIlhDaa8ibXAm",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,directSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,directSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,directSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "faro-sphere"],
  },
  appEnv: "eu-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const stagingCom = {
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.com",
    apiBaseComUrl: "https://core.api.staging.holobuilder.com",
    apiBaseEuUrl: "https://core.api.staging.holobuilder.eu",
    sphereDashboardUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.com"
        : "https://sphere.staging.holobuilder.com",
    dashboard20ComUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.com"
        : "https://sphere.staging.holobuilder.com",
    dashboard20EuUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.staging.holobuilder.eu"
        : "https://sphere.staging.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.staging.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.staging.holobuilder.eu",
    sphere10Url: "https://www.stg.farosphere.com",
    sphereEntryPageApi: "https://entry.staging.holobuilder.com/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.stg.farosphere.com",
    sphere10Auth0ClientId: "KkDGLxrxvLL9AnHFpGytGGnt6v65EjvF",
    streamAppAuth0ClientId: "aVVKUke0lRt0QfRuf5VMIlhDaa8ibXAm",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,directSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,directSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,directSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "bluebeam", "google", "faro-sphere"],
  },
  appEnv: "com-staging",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};

const prodEu = {
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.eu",
    apiBaseComUrl: "https://core.api.holobuilder.com",
    apiBaseEuUrl: "https://core.api.holobuilder.eu",
    sphereDashboardUrl: "https://sphere.holobuilder.eu",
    dashboard20ComUrl: "https://sphere.holobuilder.com",
    dashboard20EuUrl: "https://sphere.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.holobuilder.eu",
    sphere10Url: "https://www.farosphere.com",
    sphereEntryPageApi: "https://entry.holobuilder.eu/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.farosphere.com",
    sphere10Auth0ClientId: "u3aqaZnjxpvnAdfeBqvIgAmZEGNUDH54",
    streamAppAuth0ClientId: "tDrgNkzwxHR9awvwu5TI6aV39sLyq4Fv",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,sfSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,sfSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,sfSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,sfSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "faro-sphere"],
  },
  appEnv: "eu-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodCom = {
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.com",
    apiBaseComUrl: "https://core.api.holobuilder.com",
    apiBaseEuUrl: "https://core.api.holobuilder.eu",
    sphereDashboardUrl: "https://sphere.holobuilder.com",
    dashboard20ComUrl: "https://sphere.holobuilder.com",
    dashboard20EuUrl: "https://sphere.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.holobuilder.eu",
    sphere10Url: "https://www.farosphere.com",
    sphereEntryPageApi: "https://entry.holobuilder.com/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.farosphere.com",
    sphere10Auth0ClientId: "u3aqaZnjxpvnAdfeBqvIgAmZEGNUDH54",
    streamAppAuth0ClientId: "tDrgNkzwxHR9awvwu5TI6aV39sLyq4Fv",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,sfSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,sfSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,sfSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,sfSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "bluebeam", "google", "faro-sphere"],
  },
  appEnv: "com-production",
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentEuLocal = {
  ...developmentEu,
  features: {
    ...developmentEu.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const developmentComLocal = {
  ...developmentCom,
  features: {
    ...developmentCom.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingEuLocal = {
  ...stagingEu,
  features: {
    ...stagingEu.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const stagingComLocal = {
  ...stagingCom,
  features: {
    ...stagingCom.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodEuLocal = {
  ...prodEu,
  features: {
    ...prodEu.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

const prodComLocal = {
  ...prodCom,
  features: {
    ...prodCom.features,
    // We don't want to log errors from local development to Sentry
    sentryDSNKey: undefined,
  },
  isNumberedEnv: false,
  numberedEnv: null,
};

if (numberedEnv === "dev1") {
  // eslint-disable-next-line no-console
  console.log("We are in the dev 1 environment!");
}

export const runtimeConfigObject: { [envName: string]: RuntimeConfig } = {
  developmentEu,
  developmentCom,
  stagingEu,
  stagingCom,
  prodEu,
  prodCom,
  developmentEuLocal,
  developmentComLocal,
  stagingEuLocal,
  stagingComLocal,
  prodEuLocal,
  prodComLocal,
};
